export class CashAppOutbound {
  expectedDueDate: Date;
  purpose: string;
  netAmount: string;
  advanceAmount: string;
  allocatedAmount: string;
  unallocatedAmount: string;
  participant: string;
  directParticipant: string;
  directParticipantBankAccount: string;
  disbursementEntity: string;
  typeOfDisbursement: string;
  currency: string;
  product: string;
  polPriceCashAppStatus: string;

  constructor(
    expectedDueDate?: Date,
    purpose?: string,
    netAmount?: string,
    advanceAmount?: string,
    allocatedAmount?: string,
    unallocatedAmount?: string,
    participant?: string,
    directParticipant?: string,
    directParticipantBankAccount?: string,
    disbursementEntity?: string,
    typeOfDisbursement?: string,
    currency?: string,
    product?: string,
    polPriceCashAppStatus?: string
  ) {
    this.expectedDueDate = expectedDueDate;
    this.purpose = purpose;
    this.netAmount = netAmount;
    this.advanceAmount = advanceAmount;
    this.allocatedAmount = allocatedAmount;
    this.unallocatedAmount = unallocatedAmount;
    this.participant = participant;
    this.directParticipant = directParticipant;
    this.directParticipantBankAccount = directParticipantBankAccount;
    this.disbursementEntity = disbursementEntity;
    this.typeOfDisbursement = typeOfDisbursement;
    this.currency = currency;
    this.product = product;
    this.polPriceCashAppStatus = polPriceCashAppStatus;
  }
}
