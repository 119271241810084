import {
  DiscountCalcType,
  DiscountMethod,
  FacilityParticipantConfigList,
  DayCount,
  DiscountBasisType,
  ReferenceRateMethod,
  ReferenceRateTenor,
  ReferenceRateType,
  RevenueSharingProgram
} from '.';

export class FacilityConfiguration {
  id?: string | number;
  facilityId?: number;
  startDate?: string;
  endDate?: string;
  advanceRate?: string;
  marginRate?: string;
  referenceRateType?: ReferenceRateType;
  referenceRateMethod?: ReferenceRateMethod;
  referenceRateTenor?: ReferenceRateTenor;
  dayCount?: DayCount;
  discountBasisType?: DiscountBasisType;
  isBaselineDueDateCalculated?: boolean;
  discountMethod?: DiscountMethod;
  discountCalcType?: DiscountCalcType;
  basePaymentTerm?: number;
  bufferDays?: number;
  isActive?: boolean;
  isUnsoldToOverdue?: boolean; // passthroughOverdueAmountIndicator
  isUnsoldToDeferredDiscountFee?: boolean; // passthroughDeferredDiscountFeeIndicator
  isFundingToOverdue?: boolean; // newPurchasesOverdueAmountIndicator
  isFundingToDeferredDiscountFee?: boolean; // newPurchasesDeferredDiscountFeeIndicator
  revenueSharingProgram?: RevenueSharingProgram;
  facilityParticipantConfigs?: FacilityParticipantConfigList[];
  readonly isFacilityStartComplete?: boolean;

  constructor(
    id?: string | number,
    facilityId?: number,
    startDate?: string,
    endDate?: string,
    advanceRate?: string,
    marginRate?: string,
    referenceRateType?: ReferenceRateType,
    referenceRateMethodName?: ReferenceRateMethod,
    referenceRateTenor?: ReferenceRateTenor,
    dayCount?: DayCount,
    discountBasisType?: DiscountBasisType,
    calculateBaselineDueDateIndicator?: boolean,
    discountMethod?: DiscountMethod,
    discountCalculationTypeName?: DiscountCalcType,
    basePaymentTermsDaysCount?: number,
    bufferDaysCount?: number,
    activeIndicator?: boolean,
    passthroughOverdueAmountIndicator?: boolean,
    passthroughDeferredDiscountFeeIndicator?: boolean,
    newPurchasesOverdueAmountIndicator?: boolean,
    newPurchasesDeferredDiscountFeeIndicator?: boolean,
    revenueSharingProgramName?: RevenueSharingProgram,
    facilityParticipantConfigs?: FacilityParticipantConfigList[],
    isFacilityStartComplete?: boolean
  ) {
    this.id = id;
    this.facilityId = facilityId ?? null;
    this.startDate = startDate ?? null;
    this.endDate = endDate ?? null;
    this.advanceRate = advanceRate ?? null;
    this.marginRate = marginRate ?? null;
    this.referenceRateType = referenceRateType
      ? new ReferenceRateType(
          referenceRateType.id,
          referenceRateType.name,
          referenceRateType.currencyCode,
          referenceRateType.tenors,
          referenceRateType.referenceRateLockingDays
        )
      : null;
    this.referenceRateMethod = referenceRateMethodName
      ? new ReferenceRateMethod(
          referenceRateMethodName.id,
          referenceRateMethodName.name,
          referenceRateMethodName.isActive
        )
      : null;
    this.referenceRateTenor = referenceRateTenor
      ? new ReferenceRateTenor(referenceRateTenor.id, referenceRateTenor.name, referenceRateTenor.tenor)
      : null;
    this.dayCount = dayCount ? new DayCount(dayCount.id, dayCount.name, dayCount.isActive) : null;
    this.discountBasisType = discountBasisType
      ? new DiscountBasisType(discountBasisType.id, discountBasisType.name, discountBasisType.isActive)
      : null;
    this.isBaselineDueDateCalculated = calculateBaselineDueDateIndicator ?? true;
    this.discountMethod = discountMethod
      ? new DiscountMethod(discountMethod.id, discountMethod.name, discountMethod.isActive)
      : null;
    this.discountCalcType = discountCalculationTypeName
      ? new DiscountCalcType(
          discountCalculationTypeName.id,
          discountCalculationTypeName.name,
          discountCalculationTypeName.isActive
        )
      : null;
    this.basePaymentTerm = basePaymentTermsDaysCount ?? null;
    this.bufferDays = bufferDaysCount ?? null;
    this.isActive = activeIndicator ?? true;
    this.isUnsoldToOverdue = passthroughOverdueAmountIndicator ?? false;
    this.isUnsoldToDeferredDiscountFee = passthroughDeferredDiscountFeeIndicator ?? false;
    this.isFundingToOverdue = newPurchasesOverdueAmountIndicator ?? false;
    this.isFundingToDeferredDiscountFee = newPurchasesDeferredDiscountFeeIndicator ?? false;
    this.isFacilityStartComplete = isFacilityStartComplete ?? false;
    this.revenueSharingProgram = revenueSharingProgramName
      ? new RevenueSharingProgram(
          revenueSharingProgramName.id,
          revenueSharingProgramName.name,
          revenueSharingProgramName.isActive
        )
      : new RevenueSharingProgram();
    this.facilityParticipantConfigs = facilityParticipantConfigs
      ? facilityParticipantConfigs.map(
          (fpc) =>
            new FacilityParticipantConfigList(
              fpc.id,
              fpc.facilityConfigId,
              fpc.facilityId,
              fpc.participantGroupId,
              fpc.participantGroupName,
              fpc.participantName,
              fpc.settlementDays,
              fpc.marginRate,
              fpc.referenceRateType,
              fpc.referenceRateMethod,
              fpc.dayCount,
              fpc.isAutoAccept,
              fpc.isActive
            )
        )
      : new Array<FacilityParticipantConfigList>();
  }
}

export class FacilityConfigDates {
  id?: number;
  startDate?: string;
  endDate?: string;

  constructor(id?: number, startDate?: string, endDate?: string) {
    this.id = id ?? null;
    this.startDate = startDate ?? null;
    this.endDate = endDate ?? null;
  }
}
