export enum ActionType {
  GET = 'GET',
  SET = 'SET',
  SAVE = 'SAVE',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  CLEAR = 'CLEAR',
  UPDATE = 'UPDATE',
  INSERT = 'INSERT',
  TRIGGER = 'TRIGGER',
  VALIDATE = 'VALIDATE',
  RESULT = 'RESULT',
  PATCH = 'PATCH'
}

export abstract class LsAction {
  static getActionName = (base: string, type: ActionType) => {
    return `${base}\/${type}`;
  };
}
