export class FacilityConfigList {
  id: number;
  startDate?: string;
  endDate?: string;
  advanceRate?: number;
  marginRate?: number;
  referenceRateType?: string;
  revenueSharingProgramId?: string;
  revenueSharingProgramName?: string;

  constructor(
    id: number,
    startDate?: string,
    endDate?: string,
    advanceRate?: number,
    marginRate?: number,
    referenceRateType?: string,
    revenueSharingProgramId?: string,
    revenueSharingProgramName?: string
  ) {
    this.id = id ?? null;
    this.startDate = startDate ?? null;
    this.endDate = endDate ?? null;
    this.advanceRate = advanceRate ?? null;
    this.marginRate = marginRate ?? null;
    this.referenceRateType = referenceRateType ?? null;
    this.revenueSharingProgramId = revenueSharingProgramId ?? null;
    this.revenueSharingProgramName = revenueSharingProgramName ?? null;
  }
}
