import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DateConverter {
  toDate(date: string): Date {
    if (!date.match(/[\d]{4}\-[\d]{2}-[\d]{2}/g)) {
      throw new Error("Date not of format 'yyyy-mm-dd'");
    }
    const [year, month, day] = date.split('-');
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
  }
}
