import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class ChannelProgram extends GenericDropdownOption<number> {
  channelId: string;
  companyId: number;
  integrationTypeId: number;
  channelName: string;

  constructor(
    id: number,
    channelId: string,
    companyId: number,
    integrationTypeId: number,
    companyName: string,
    channelName: string
  ) {
    super(id, companyName, true);
    this.channelId = channelId;
    this.companyId = companyId;
    this.integrationTypeId = integrationTypeId;
    this.channelName = channelName;
  }
}
