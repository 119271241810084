export const origin = window.location.origin;

export enum ENV_NAME {
  LOCAL,
  DEV,
  QA,
  QA2,
  QA3,
  RSM,
  STAGING,
  STAGEOB2,
  STAGE1,
  STAGE2,
  STAGE3,
  PROD
}
