export class FacilityList {
  id?: number;
  productCurrencyCode?: string;
  sellerName?: string;
  debtorName?: string;
  sourceChannelCompanyName?: string;
  integrationTypeName?: string;
  isAutoXcelEnabled?: boolean;
  isRemittanceEmailSent?: boolean;
  channelAndIntegrationType?: string;
  lienPositionName?: string;
  payXcelTypeName?: string;
  facilityGroupId?: string;
  constructor(
    id?: number,
    productCurrencyCode?: string,
    sellerName?: string,
    debtorName?: string,
    sourceChannelCompanyName?: string,
    integrationTypeName?: string,
    isAutoXcelEnabled?: boolean,
    isRemittanceEmailSent?: boolean,
    lienPositionName?: string,
    payXcelTypeName?: string,
    facilityGroupId?: string
  ) {
    this.id = id ?? null;
    this.productCurrencyCode = productCurrencyCode ?? null;
    this.sellerName = sellerName ?? null;
    this.debtorName = debtorName ?? null;
    this.sourceChannelCompanyName = integrationTypeName ?? null;
    this.integrationTypeName = integrationTypeName ?? null;
    this.isAutoXcelEnabled = isAutoXcelEnabled ?? null;
    this.isRemittanceEmailSent = isRemittanceEmailSent ?? null;
    this.channelAndIntegrationType = sourceChannelCompanyName + ' ' + integrationTypeName;
    this.lienPositionName = lienPositionName ?? null;
    this.payXcelTypeName = payXcelTypeName ?? null;
    this.facilityGroupId = facilityGroupId ?? null;
  }
}
