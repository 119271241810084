import { MaturityTypes } from './MaturityOption';

export class MaturityCalendar {
  public maturityType?: MaturityTypes;
  public daysSelected?: number[];

  constructor(maturityType?: MaturityTypes, daysSelected?: number[]) {
    this.maturityType = maturityType ?? null;
    this.daysSelected = daysSelected ?? null;
  }
}
