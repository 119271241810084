import { ProductCurrency } from './ProductCurrency';
import { LimitBasisType } from './LimitBasisType';
import { MaturityOffset } from './MaturityOffset';
import { MaturityTypes } from './MaturityOption';

export class DebtorProductCurrency {
  debtorId?: number;
  productCurrency: ProductCurrency;
  creditLimitAmount: string;
  limitBasisType: LimitBasisType;
  isBankAccountVerified: boolean;
  isUnfinancedCollected: boolean;
  maturityOffset: MaturityOffset;
  maturityType: MaturityTypes;
  maturityDaysOfWeek: number[];
  maturityDaysOfMonth: number[];
  isActive: boolean;

  constructor(
    isActive?: boolean,
    debtorId?: number,
    productCurrency?: ProductCurrency,
    creditLimitAmount?: string,
    limitBasisType?: LimitBasisType,
    isBankAccountVerified?: boolean,
    isUnfinancedCollected?: boolean,
    maturityOffset?: MaturityOffset,
    maturityType?: MaturityTypes,
    maturityDaysOfWeek?: number[],
    maturityDaysOfMonth?: number[]
  ) {
    this.debtorId = debtorId ?? null;
    this.productCurrency = productCurrency
      ? new ProductCurrency(
          productCurrency.id,
          productCurrency.productId,
          productCurrency.isActive,
          productCurrency.currency
        )
      : new ProductCurrency();
    this.creditLimitAmount = creditLimitAmount ?? null;
    this.limitBasisType = limitBasisType
      ? new LimitBasisType(limitBasisType.id, limitBasisType.name, limitBasisType.isActive)
      : new LimitBasisType();
    this.isBankAccountVerified = isBankAccountVerified ?? false;
    this.isUnfinancedCollected = isUnfinancedCollected ?? true;
    this.maturityOffset = maturityOffset
      ? new MaturityOffset(maturityOffset.id, maturityOffset.name, maturityOffset.sortOrder, maturityOffset.isActive)
      : new MaturityOffset();
    this.maturityType = maturityType ?? null;
    this.maturityDaysOfWeek = maturityDaysOfWeek ?? new Array<number>();
    this.maturityDaysOfMonth = maturityDaysOfMonth ?? new Array<number>();
    this.isActive = isActive ?? true;
  }
}
