import { NgModule } from '@angular/core';
import { DateConverter } from './DateConverter';
import { Guid } from './Guid';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [Guid, DateConverter]
})
export class UtilsModule {}
