import { ActionType, LsAction } from '../../../../AppStateManagement/LsAction';
import { createAction, props } from '@ngrx/store';
import { PageRequest, FacilityConfiguration, FacilityConfigDates, ActivePage } from '../../../../Models';

export abstract class FacilityConfigurationActions extends LsAction {
  static FACILITY_CONFIG: Readonly<string> = 'FACILITY_CONFIG';
  static FACILITY_CONFIGS: Readonly<string> = 'FACILITY_CONFIGS';
  static FACILITY_CONFIG_DATES: Readonly<string> = 'FACILITY_CONFIG_DATES';
  static FACILITY_CONFIGS_ACTIVE_PAGE: Readonly<string> = 'FACILITY_CONFIGS_ACTIVE_PAGE';

  static getFacilityConfigDates = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG_DATES, ActionType.GET),
    props<{ id: string | number; facilityId: number }>()
  );

  static setFacilityConfigDates = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG_DATES, ActionType.SET),
    props<{ facilityConfigDates: FacilityConfigDates[] }>()
  );

  static getFacilityConfig = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG, ActionType.GET),
    props<{ id: number; facilityId: number }>()
  );

  static setFacilityConfig = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG, ActionType.SET),
    props<{ facilityConfig: FacilityConfiguration }>()
  );

  static createFacilityConfig = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG, ActionType.SAVE),
    props<{ facilityConfig: FacilityConfiguration }>()
  );

  static updateFacilityConfig = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG, ActionType.UPDATE),
    props<{ facilityConfig: FacilityConfiguration }>()
  );

  static facilityConfigSaveSuccessful = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG, ActionType.COMPLETE)
  );

  static facilityConfigSaveUnsuccessful = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG, ActionType.ERROR),
    props<{ errors: string[] }>()
  );

  static getFacilityConfigsActivePage = createAction(
    FacilityConfigurationActions.getActionName(
      FacilityConfigurationActions.FACILITY_CONFIGS_ACTIVE_PAGE,
      ActionType.GET
    ),
    props<{ pageRequest: PageRequest }>()
  );

  static setFacilityConfigsActivePage = createAction(
    FacilityConfigurationActions.getActionName(
      FacilityConfigurationActions.FACILITY_CONFIGS_ACTIVE_PAGE,
      ActionType.SET
    ),
    props<{ activePage: ActivePage }>()
  );

  static clearFacilityConfig = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIG, ActionType.CLEAR)
  );

  static clearFacilityConfigs = createAction(
    FacilityConfigurationActions.getActionName(FacilityConfigurationActions.FACILITY_CONFIGS, ActionType.CLEAR)
  );
}
