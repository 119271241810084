import { GenericDropdownOption } from '@limestone/ls-shared-modules';

export class MaturityOption extends GenericDropdownOption<number> {
  constructor(id?: number, name?: string) {
    super(id, name);
  }
}

export const monthlyMaturity = 'Monthly on';
export const weeklyMaturity = 'Weekly on';
export const noMaturity = '';

export const maturityTypes = [noMaturity, monthlyMaturity, weeklyMaturity] as const;

export class MaturityOptionsConstants {
  public static monthlyOptions: MaturityOption[] = [
    new MaturityOption(1, '1st'),
    new MaturityOption(2, '2nd'),
    new MaturityOption(3, '3rd'),
    new MaturityOption(4, '4th'),
    new MaturityOption(5, '5th'),
    new MaturityOption(6, '6th'),
    new MaturityOption(7, '7th'),
    new MaturityOption(8, '8th'),
    new MaturityOption(9, '9th'),
    new MaturityOption(10, '10th'),
    new MaturityOption(11, '11th'),
    new MaturityOption(12, '12th'),
    new MaturityOption(13, '13th'),
    new MaturityOption(14, '14th'),
    new MaturityOption(15, '15th'),
    new MaturityOption(16, '16th'),
    new MaturityOption(17, '17th'),
    new MaturityOption(18, '18th'),
    new MaturityOption(19, '19th'),
    new MaturityOption(20, '20th'),
    new MaturityOption(21, '21st'),
    new MaturityOption(22, '22nd'),
    new MaturityOption(23, '23rd'),
    new MaturityOption(24, '24th'),
    new MaturityOption(25, '25th'),
    new MaturityOption(26, '26th'),
    new MaturityOption(27, '27th'),
    new MaturityOption(28, '28th'),
    new MaturityOption(29, '29th'),
    new MaturityOption(30, '30th'),
    new MaturityOption(31, '31st')
  ];
  public static weeklyOptions: MaturityOption[] = [
    new MaturityOption(0, 'Sunday'),
    new MaturityOption(1, 'Monday'),
    new MaturityOption(2, 'Tuesday'),
    new MaturityOption(3, 'Wednesday'),
    new MaturityOption(4, 'Thursday'),
    new MaturityOption(5, 'Friday'),
    new MaturityOption(6, 'Saturday')
  ];
}

export type MaturityTypes = (typeof maturityTypes)[number];
